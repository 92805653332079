const SUCCESS_STATUSES = [
  'Approved',
  'Accepted as Complete (Screening Activity)',
  'Accepted as Complete (Main Activity)',
  'Accepted as Complete'
]

const ERROR_STATUSES = [
  'Participant blocked for low quality',
  'Rejected due to low quality',
  'Rejected',
  'Issue reported by participant',
  'Participant blocked for suspicious data',
  'Issue reported in main activity',
  'Unpaid',
  'Participant blocked for previous participation',
  'Participant blocked as already flagged'
]

export const getAssignmentStatusClassName = (status) => {
  if (SUCCESS_STATUSES.includes(status))
    return 'color'

  if (ERROR_STATUSES.includes(status)) {
    return 'text-danger'
  }

  if (status === 'Ineligible') {
    return 'text-orange-important'
  }

  return ''
}

export const getStatusReadable = (isScreeningEnabled, status) => {
  if (isScreeningEnabled && status === 'Issue reported by participant') {
    return 'Issue reported in Screening Activity'
  }

  if (status === 'Issue reported in main activity') {
    return 'Issue reported in Main Activity'
  }

  return status
}
