import React, {useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'

/* 3rd party components */
import {useFormik} from 'formik'
import qs from 'qs'
import swal from 'sweetalert'

/* Custom components & helpers */
import RunDetail from './RunDetail'
import RunProgress from './RunProgress'
import RunFeedback from './RunFeedback'
import RunParticipants from './RunParticipants'
import Spinner from './common/Spinner'
import PauseRun from './run/PauseRun'
import SetAttribute from './SetAttribute'
import SendMessage from './SendMessage'
import SendBonus from './SendBonus'
import UnpauseRun from './run/UnpauseRun'
import DuplicateRun from './run/DuplicateRun'
import {fayeClient} from './helpers/FayeClient'
import SpendingLimitWarning from './common/SpendingLimitWarning'
import AddCardWarning from './common/AddCardWarning'
import {isCintPlatform, isMturkPlatform} from '../utils/constants'

/* Redux actions */
import {
  runFormProfilingVariablesFetch,
} from '../redux/actions'
import {userBalanceFetch} from '../redux/actionss/userActions'
import {
  runFetch,
  updateRunStateChange,
  runParticipantsFetch,
  participantSendMessageInit,
  runRejectedAssignmentsUpdate,
  sendBonusInit,
  runReset,
  participantAttributesFetch,
  participantCurrentAttributesFetch
} from '../redux/actionss/runActions'
import {getQuoteForMturk} from "../redux/actionss/quoteActions";
import WidgetPopup from './common/WidgetPopup'

/* Constants */
const $ = window.$

const Run = props => {
  const queryParams = qs.parse(props.location.search, {ignoreQueryPrefix: true})
  const id = queryParams.id
  const isPublished = queryParams.isPublished

  const [showSpendingLimitWarning, setShowSpendingLimitWarning] = useState(false)
  const [showAddCardWarning, setShowAddCardWarning] = useState(false)
  const [spendingLimitWarningMessage, setSpendingLimitWarningMessage] = useState('')
  const spendingLimitCallbackRef = useRef()
  const addCardCallbackRef = useRef()

  const getInitialParticipantQuery = () => {
    if (queryParams.participant_id) {
      return queryParams.participant_id
    } else {
      return ''
    }
  }

  useEffect(() => {
    props.runFetch(id)

    // Cleanup
    return () => {
      $.magnificPopup?.close()
      if (swal.getState()?.isOpen) {
        swal.close()
      }
      props.runReset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [popupVisibility, setPopupVisibility] = useState(false)

  useEffect(() => {
    if (popupVisibility) {
      let closeOnEsc = true

      if(popupId === 'nps-survey-popup'){
        closeOnEsc = false
      }

      $.magnificPopup.open({
        items: {src: `#${popupId}`},
        type: 'inline',
        callbacks: {
          close: () => setPopupVisibility(false)
        },
        closeOnBgClick: false,
        enableEscapeKey: closeOnEsc
      })
    } else {
      $.magnificPopup?.close()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupVisibility])

  const [isSearched, setIsSearched] = useState(false)
  const searchFormik = useFormik({
    initialValues: {
      query: getInitialParticipantQuery()
    },
    onSubmit: values => {
      setIsSearched(true)
      props.runParticipantsFetch(id, 1, values.query)
    }
  })

  const handleClear = () => {
    if (isSearched === true && searchFormik.values.query === '') {
      props.runParticipantsFetch(id, 1)
      setIsSearched(false)
    }
  }

  const getActiveTab = () => {
    if (queryParams.open_complaints) {
      return 'feedback'
    } else if (queryParams.open_participants) {
      return 'participants'
    } else if (queryParams.active_tab) {
      return queryParams.active_tab
    } else {
      return 'details'
    }
  }

  const [activeTab, setActiveTab] = useState(getActiveTab())
  const isActiveTab = (tab) => (activeTab === tab)

  const onTabClick = (event) => {
    event.preventDefault()
    const tabText = event.target.text
    if (tabText) {
      const tab = tabText.toLowerCase()
      setActiveTab(tab)
      props.history.push(`/runs/show?id=${id}&active_tab=${tab}`)
    }
  }

  const [popupProps, setPopupProps] = useState(null)
  const [popupId, setPopupId] = useState(null)

  /** Opens the specified modal
   *
   * @param {string} id Id of the modal to be opened
   * @param {Object} properties
   * @param {Event} [event]
   */
  const openPopup = (id, properties, event) => {
    if (event) event.preventDefault()

    if (id === 'set-attribute-popup') {
      props.participantAttributesFetch()
      if (properties?.participant_id) {
        props.participantCurrentAttributesFetch(properties.participant_id)
      }
    } else if (id === 'message-popup') {
      props.participantSendMessageInit()
    } else if (id === 'bonus-popup') {
      props.sendBonusInit()
      props.getQuoteForMturk({platform_type: run.platform_type})
      props.userBalanceFetch()
    }

    setPopupProps(properties)
    setPopupId(id)
    setPopupVisibility(true)
  }

  const closePopup = () => {
    setPopupVisibility(false)
  }

  const run = props.run
  const isMturk = run && isMturkPlatform(run.platform_type)
  const isCint = run && isCintPlatform(run.platform_type)
  const isRunPublishing = ['Created', 'Publishing', 'Republishing', 'Qualifying'].includes(run?.label_name)

  useEffect(() => {
    const subscription = fayeClient.subscribe(`/runStateChanged/${id}`, (data) => {
      props.updateRunStateChange(data.run, 'run')
      if (data.rejected_assignment)
        props.runRejectedAssignmentsUpdate(data.rejected_assignment)
    })

    return () => subscription.cancel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (run?.country_name && isCint) {
      props.profilingVariablesFetch(run.country_name, run.platform_id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [run?.country_name, isCint])

  if (props.isLoading || !run) {
    return <Spinner/>
  }

  return (
    <div>
      <div className="run_detail_tabs">
        <div className="task-list-header top_line clearfix">
          <Link to={`/projects/show?id=${run.task_id}`} className="btn btn-default pull-left">
            <i className="icon ion-android-arrow-back"/>
            All Runs
          </Link>

          {isActiveTab('details') && (
            <div className="run-actions pull-right">
              <DuplicateRun
                runId={run.id}
                labelName={run.label_name}
                ui="button"
              />
              <PauseRun
                page="run"
                id={run.id}
                platformType={run.platform_type}
                totalAcceptedCount={run.total_accepted_count}
                labelName={run.label_name}
                pauseUI={`button`}
              />

              <UnpauseRun
                unpauseUI={`button`}
                page="run"
                id={id}
                platformType={run.platform_type}
                isExpired={run.is_expired}
                pausedForQualityInCint={run.paused_for_quality_cint}
                pausedForRepricingInCint={run.paused_for_repricing_cint}
                closedByCint={run.closed_by_cint}
                labelName={run.label_name}
                reasonForPause={run.reason_for_pause}
                paymentPerSubmission={props.run.payment_per_submission}
                user_credits_unused={props.user_credits_unused}
                currentUser={props.currentUser}
                setShowSpendingLimitWarning={setShowSpendingLimitWarning}
                setShowAddCardWarning={setShowAddCardWarning}
                spendingLimitCallbackRef={spendingLimitCallbackRef}
                addCardCallbackRef={addCardCallbackRef}
                {...props.run}
              />

            </div>
          )}

          {isActiveTab('participants') && (!!props.assignmentsLength || isSearched) && (
            <div className="pull-right search-bar">
              <form onSubmit={searchFormik.handleSubmit} className="participants-search">
                <div className="input-group">
                  <input
                    type="text"
                    name="query"
                    id="query"
                    placeholder="Search participants by ID"
                    className="form-control search-input"
                    onChange={searchFormik.handleChange}
                    value={searchFormik.values.query}
                    onBlur={handleClear}
                  />
                  <input
                    type="submit"
                    disabled={props.isLoading}/>
                  <span className="input-group-addon clickable"/>
                </div>
              </form>
            </div>
          )}
        </div>

        <h1 className="word-break-all">{run.name}</h1>
        {run.batch_creation_percentage < 100 && isRunPublishing && (
          <div className="run_detail_tabs progress mb-3">
            <div className="indicator">
              <div className="row">
                <div className="col">
                  <div className="title">
                    {(run.label_name === 'Publishing' || run.label_name === 'Republishing') && (
                      <div>
                        <span className="completed">
                          {run.label_name} the run is in progress ({run.batch_creation_percentage || 0}%)
                        </span>
                        <div className="line">
                          <div className="line_progress" style={{width: `${run.batch_creation_percentage || 0}%`}}>
                          </div>
                        </div>
                      </div>
                    )}
                    {run.label_name === 'Qualifying' && (
                      <span className="completed po-text">
                        Preparing participant eligibility... ( It may take few minutes. You can close this window
                        now! )
                      </span>
                    )}
                    {run.label_name === 'Created' && (
                      <span className="completed">
                        Preparing to publish the run...
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {run.label_name !== 'Publishing' && run.label_name !== 'Republishing' && (
          <div className="row">
            <div className="col-md-8">
              <ul className="tabs d-flex">
                <li>
                  <Link
                    to=""
                    onClick={onTabClick}
                    className={isActiveTab('details') ? 'active' : ''}
                  >
                    Details
                  </Link>
                </li>
                {run.status !== 'draft' && (
                  <>
                    <li>
                      <Link
                        to=""
                        onClick={onTabClick}
                        className={isActiveTab('progress') ? 'active' : ''}
                      >
                        Progress
                      </Link>
                    </li>
                    <li>
                      <Link
                        to=""
                        onClick={onTabClick}
                        className={isActiveTab('feedback') ? 'active' : ''}
                      >
                        Feedback
                      </Link>
                    </li>
                    <li>
                      <Link
                        to=""
                        onClick={onTabClick}
                        className={isActiveTab('participants') ? 'active' : ''}
                      >
                        Participants
                      </Link>
                    </li>
                  </>
                )}
              </ul>
            </div>
            <div className='col-md-4'>
              {run.total_accepted_count > 0 && (
                <div className='wrapper_link'>
                  <a
                    className="hypothesize-btn"
                    href="https://www.hypothesize.io/"
                    target="_blank">
                    <i className="pr-2 fas fa-flask"/>
                    Analyze your data with Hypothesize
                    <i className="pl-2 fas fa-external-link-alt"/>
                  </a>
                </div>
              )}
            </div>
          </div>
        )}

        <div className="tab-content paddig-lr-0">
          {isActiveTab('details') && (
            <RunDetail
              {...run}
              isMturk={isMturk}
              isCint={isCint}
              setShowSpendingLimitWarning={setShowSpendingLimitWarning}
              setShowAddCardWarning={setShowAddCardWarning}
              spendingLimitCallbackRef={spendingLimitCallbackRef}
              addCardCallbackRef={addCardCallbackRef}
            />
          )}
          {isActiveTab('progress') && (
            <RunProgress
              {...run}
              setActiveTab={setActiveTab}
              isMturk={isMturk}
              isCint={isCint}
            />
          )}
          {isActiveTab('feedback') && (
            <RunFeedback
              {...run}
              isMturk={isMturk}
              openPopup={openPopup}
              setShowSpendingLimitWarning={setShowSpendingLimitWarning}
              setShowAddCardWarning={setShowAddCardWarning}
              spendingLimitCallbackRef={spendingLimitCallbackRef}
              addCardCallbackRef={addCardCallbackRef}
            />
          )}
          {isActiveTab('participants') && (
            <RunParticipants
              {...run}
              isMturk={isMturk}
              openPopup={openPopup}
              isSearched={isSearched}
              query={searchFormik.values.query}
              setShowSpendingLimitWarning={setShowSpendingLimitWarning}
              setShowAddCardWarning={setShowAddCardWarning}
              spendingLimitCallbackRef={spendingLimitCallbackRef}
              addCardCallbackRef={addCardCallbackRef}
            />
          )}
        </div>
      </div>

      {run.user.ask_nps_survey === true && isPublished === 'true' && (
        <WidgetPopup
          {...popupProps}
          closePopup={closePopup}
          openPopup={openPopup}
          userId={run.user.id}
        />
      )}

      <SendMessage
        {...popupProps}
        closePopup={closePopup}
        openPopup={openPopup}
        runId={id}
        platformType={run.platform_type}
      />

      <SetAttribute
        {...popupProps}
        closePopup={closePopup}
        openPopup={openPopup}
        runId={id}
        platformType={run.platform_type}
      />

      <SendBonus
        {...popupProps}
        closePopup={closePopup}
        openPopup={openPopup}
        runId={id}
        platformType={run.platform_type}
        setShowSpendingLimitWarning={setShowSpendingLimitWarning}
        setShowAddCardWarning={setShowAddCardWarning}
        setSpendingLimitWarningMessage={setSpendingLimitWarningMessage}
        spendingLimitCallbackRef={spendingLimitCallbackRef}
        addCardCallbackRef={addCardCallbackRef}
      />

      <SpendingLimitWarning
        showSpendingLimitWarning={showSpendingLimitWarning}
        setShowSpendingLimitWarning={setShowSpendingLimitWarning}
        message={spendingLimitWarningMessage}
      />

      <AddCardWarning
        showAddCardWarning={showAddCardWarning}
        setShowAddCardWarning={setShowAddCardWarning}
        callbackRef={addCardCallbackRef}
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    ...state.runDetail,
    assignmentsLength: state.runParticipants?.assignments?.length,
  }
}

const mapDispatchToProps = dispatch => ({
  runFetch: (id) => dispatch(runFetch(id)),
  participantAttributesFetch: () => dispatch(participantAttributesFetch()),
  participantCurrentAttributesFetch: (participantId) => dispatch(participantCurrentAttributesFetch(participantId)),
  runParticipantsFetch: (runId, pageNumber, query) => dispatch(runParticipantsFetch(runId, pageNumber, query)),
  participantSendMessageInit: () => dispatch(participantSendMessageInit()),
  sendBonusInit: () => dispatch(sendBonusInit()),
  updateRunStateChange: (run, ui) => dispatch(updateRunStateChange(run, ui)),
  profilingVariablesFetch: (country, platformId) => dispatch(runFormProfilingVariablesFetch(country, platformId)),
  runRejectedAssignmentsUpdate: (rejectedAssignment) => dispatch(runRejectedAssignmentsUpdate(rejectedAssignment)),
  runReset: () => dispatch(runReset()),
  userBalanceFetch: () => dispatch(userBalanceFetch()),
  getQuoteForMturk: (params) => dispatch(getQuoteForMturk(params))
})

export default connect(mapStateToProps, mapDispatchToProps)(Run)
