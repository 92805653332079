import React, {useEffect, useRef, useState} from 'react'
import {connect, useDispatch, useSelector} from 'react-redux'
import {withRouter} from 'react-router-dom'
import qs from 'qs'
import swal from 'sweetalert'

// Custom components & helpers
import RunFormActivityTab from './RunFormActivityTab'
import RunFormOverview from './RunFormOverview'
import RunFormConfirmationTab from './RunFormConfirmationTab'
import RunFormAdditionalAttributesTab from './RunFormAdditionalAttributesTab'
import RunFormParticipantsTab from './RunFormParticipantsTab'
import RunFormDetailsTab from './RunFormDetailsTab'
import Spinner from '../../common/Spinner'
import {
  ageFilterForCint,
  genderFilterForCint,
  generateQuotaGroups,
  getActivityLanguageById,
  getFormattedCintAttributes,
  getFormattedCintQuotas,
  getFormattedVariableIds,
} from './RunUtils'
import {
  clearLinkVerificationData,
  pickPropertiesFromObject,
  removePropertiesFromObject,
  scrollToTop
} from '../../common/Helpers'
import SpendingLimitWarning from '../../common/SpendingLimitWarning'
import AddCardWarning from '../../common/AddCardWarning'
import {CINT_REGION_CATEGORY_NAME, isCintPlatform, isMturkPlatform} from '../../../utils/constants'

// Redux actions
import {
  createAndStartRunInit,
  createAndStartRunReset,
  createAndStartRunSubmit,
  initializeRunForm,
  runFormQualifiedWorkersCountFetch,
  runFormReset,
  runUpdateFetch,
  runUpdateReset
} from '../../../redux/actions'
import DemoButtons from '../../common/DemoButtons'
import {GA_EVENTS, sendGAEvent} from '../../../utils/helper'

const $ = window.$

export const TABS = Object.freeze({
  DETAILS: {
    navigationText: 'Set Up Your Run',
    titleText: 'Set Up Your Run',
    explainerVideoStartTimeInSecs: 0,
    page: 'run_form_set_up_your_run'
  },
  PARTICIPANTS: {
    navigationText: 'Target Audience',
    titleText: 'Define Your Target Audience',
    explainerVideoStartTimeInSecs: 77,
    page: 'run_form_target_audience'
  },
  ADDITIONAL_ATTRIBUTES: {
    navigationText: 'Additional Participant Insights',
    titleText: 'Additional Participant Insights',
    explainerVideoStartTimeInSecs: 77,
    page: 'run_form_additional_participant_insights'
  },
  ACTIVITY: {
    navigationText: 'Describe Your Activity',
    titleText: 'Describe Your Activity',
    explainerVideoStartTimeInSecs: 153,
    page: 'run_form_describe_your_activity'
  },
  CONFIRMATION: {
    navigationText: 'Final Steps',
    titleText: 'Final Required Steps',
    explainerVideoStartTimeInSecs: 193,
    page: 'run_form_final_steps'
  },
})

const EnhancedRunForm = (props) => {
  // Query params
  const queryParams = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  })
  const projectId = queryParams.project_id
  const runId = queryParams.run_id || queryParams.id
  const gtDataId = queryParams.gt_data_id


  // Variables
  const [activeTab, setActiveTab] = useState(TABS.DETAILS)
  const [isSaving, setIsSaving] = useState(false)
  const [isPublishing, setIsPublishing] = useState(false)
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false)
  const [maxInterviewLength, setMaxInterviewLength] = useState()
  const [minIncidenceRate, setMinIncidenceRate] = useState()
  const [maxIncidenceRate, setMaxIncidenceRate] = useState()
  const [showSpendingLimitWarning, setShowSpendingLimitWarning] = useState(false)
  const [showAddCardWarning, setShowAddCardWarning] = useState(false)
  const [showBudgetChangedWarning, setShowBudgetChangedWarning] = useState(false)
  const [screeninRate, setScreeninRate] = useState()
  const [showWarning, setShowWarning] = useState(false)
  const [mturkEligibleCountAbortController, setMturkEligibleCountAbortController] = useState(null)
  const [openScreeningSetup, setOpenScreeningSetup] = useState(false)
  const [defaultPlatformId, setDefaultPlatformId] = useState(null)
  const [minAgeForCountry, setMinAgeForCountry] = useState(null)
  const [oldCountryname, setOldCountryname] = useState(null)
  const addCardCallbackRef = useRef()
  const dispatch = useDispatch()

  const platforms = useSelector(state => state.platformList?.platforms) || []

  // Init
  useEffect(() => {
    dispatch(initializeRunForm(runId, projectId, gtDataId, props.isRunUpdate))
  }, [dispatch, runId, projectId, gtDataId, props.isRunUpdate])

  // Cleanup
  useEffect(() => {
    return () => {
      $.magnificPopup?.close()
      if (swal.getState()?.isOpen) {
        swal.close()
      }
      clearLinkVerificationData()
      dispatch(runFormReset())
    }
  }, [dispatch])


  // Event handlers
  const handleCancel = () => {
    sendGAEvent(
      GA_EVENTS.CLICK_RUN_FORM_CANCEL,
      {
        positly_user_id: props.positly_user_id,
        platform: props.platform.full_name,
        page: activeTab.page
      }
    )

    if (runId) {
      props.history.push(`/runs/show?id=${runId}`)
    } else {
      if (props.runsCount === 0) {
        props.history.push('/projects/list')
      } else {
        props.history.push(`/projects/show?id=${projectId}`)
      }
    }
  }

  const publishRun = (isDraft = false) => {
    if (isDraft)
      setIsSaving(true)
    else
      setIsPublishing(true)

    const run = prepareRunForApi(isDraft)

    if (props.isRunUpdate) {
      if (!isDraft)
        run.quick_publish = true
      dispatch(runUpdateFetch(run, runId, props.platform.mode))
    } else {
      dispatch(createAndStartRunInit())
      dispatch(createAndStartRunSubmit(run, props.platform.mode))
    }
  }

  const saveAsDraft = () => {
    sendGAEvent(GA_EVENTS.CLICK_SAVE_DRAFT, {positly_user_id: props.positly_user_id, page: activeTab.page})
    publishRun(true)
  }


  // Functions
  const isTabActive = (tab) => tab.navigationText === activeTab.navigationText

  const isMturk = isMturkPlatform(props.platform.platform_type)

  const isCint = isCintPlatform(props.platform.platform_type)

  const isActivityLanguageEnglish = (activityLanguageId) => {
    const languageId = activityLanguageId || props.activityLanguageId
    const activityLanguage = getActivityLanguageById(props.languages, languageId)
    return activityLanguage.name === 'English'
  }

  const switchToPanelPlatform = () => {
    const platform = platforms.find(p => p.platform_type === 'Cint')

    if(platform){
      setDefaultPlatformId(platform.id)
    }
  }

  const isAnyQuotaInvalid = isCint && props.cintQuotas.some(q => !q.isValid)

  const commonProps = {
    isMturk,
    isCint,
    isTabActive,
    activeTab,
    setActiveTab,
    isSaving,
    isPublishing,
    handleCancel,
    saveAsDraft,
    publishRun,
    runId,
    projectId,
    gtDataId,
    isActivityLanguageEnglish,
    maxInterviewLength,
    setMaxInterviewLength,
    minIncidenceRate,
    maxIncidenceRate,
    setMinIncidenceRate,
    setMaxIncidenceRate,
    setShowSpendingLimitWarning,
    showBudgetChangedWarning,
    setShowBudgetChangedWarning,
    screeninRate,
    setScreeninRate,
    showWarning,
    setShowWarning,
    openScreeningSetup,
    setOpenScreeningSetup,
    platform: props.platform,
    isDetailsFormValid: props.isDetailsFormValid,
    isParticipantsFormValid: props.isParticipantsFormValid,
    isScreeningFormValid: props.isScreeningFormValid,
    isActivityFormValid: props.isActivityFormValid,
    isQualityFilterValid: props.isQualityFilterValid,
    isRunUpdate: props.isRunUpdate,
    ageFilterForCint,
    genderFilterForCint,
    isAnyQuotaInvalid,
    switchToPanelPlatform,
    prescreen_data: props.prescreen_data,
    minAgeForCountry,
    setMinAgeForCountry
  }

  const propsForConfirmationTab = {
    showAdvancedSettings,
  }


  // Private functions
  const prepareRunForApi = (isDraft) => {
    //Params required by API
    const requiredParams = [
      'platform_id',
      'task_id',
      'name',
      'task_parameters',
      'is_draft',
      'query_string_attributes',
      'additional_attribute_ids',
      'collects_pii',
      'activity_language_id',
      'completion_link_verification_id',
      'screen_in_link_verification_id',
      'screen_out_link_verification_id',
      'agree_screening_guidelines',
      'activity_status'
    ]

    const run = {
      ...props.run,
      task_parameters: getFilteredTaskParams(props.run.task_parameters),
      is_draft: isDraft,
      collects_pii: (props.run.collects_pii && props.run.agree_pii),
      additional_attribute_ids: getAdditionalAttributeIds(props.run.additional_attributes),
      prescreen_data: props.run.prescreen_data
    }

    if (isMturk) {
      run.prescreen_ids = getFormattedPrescreenIds(props.run.prescreen_ids, props.run.prescreen_data)
      run.filters = getFormattedFilters(props.run.filters)

      requiredParams.push('filters', 'prescreen_ids', 'prescreen_data')
    } else {
      run.cint_profiling_variables = getFormattedCintProfilingVariables(props.run.cint_filters)
      run.cint_region_variables = getFormattedCintRegionVariables(props.run.cint_filters)
      const cint_attributes = getFormattedCintAttributes(props.run.cint_filters)
      run.task_parameters.age_min = cint_attributes.age_min
      run.task_parameters.age_max = cint_attributes.age_max
      run.task_parameters.gender = cint_attributes.gender
      run.cint_quotas = getFormattedCintQuotas(props.run.cint_quotas)

      const params = {
        country_name: run.task_parameters.country_name,
        number_of_submissions: parseInt(run.task_parameters.number_of_submissions),
        max_time_per_submission: parseInt(run.task_parameters.max_time_per_submission),
        expires_after: parseInt(run.task_parameters.expires_after),
        variable_ids: getFormattedVariableIds(props.run.cint_filters),
        age_min: cint_attributes.age_min,
        age_max: cint_attributes.age_max,
        gender: cint_attributes.gender
      }
      run.cint_quota_groups = JSON.stringify(generateQuotaGroups(params, props.run.cint_quotas, props.run.cint_interlocked_quotas))
      run.cint_interlocked_quotas = JSON.stringify(run.cint_interlocked_quotas)

      requiredParams.push(
          'cint_profiling_variables',
          'cint_quotas',
          'cint_quota_groups',
          'cint_interlocked_quotas',
          'cint_region_variables',
          'prescreen_data'
      )
    }

    // Pick only the params required by API
    return pickPropertiesFromObject(run, requiredParams)
  }

  const getTaskDataUrl = (taskParameters) => {
    const {task_url, task_data_url} = taskParameters
    return task_url && task_url.includes('guidedtrack.com') ? task_data_url : ''
  }

  const getAdditionalAttributeIds = (additionalAttributes) => {
    return additionalAttributes.map(attribute => attribute.id)
  }

  const getFormattedPrescreenIds = (prescreenIds, prescreenData) => {
    if (!isMturk || prescreenData?.ps_type === 'does_not_matter')
      return []

    return prescreenIds.map((prescreenId) => prescreenId.match(/ID:\s(.*)\)$/)[1])
  }

  const getFormattedFilters = (filters) => {
    if (!isMturk)
      return []

    const demographicFilters = filters.filter(filter => filter.is_demographic)
    const customAttributeFilters = filters.filter(filter => !filter.is_demographic)

    const formattedDemographicFilters = formatDemographicFilters(demographicFilters)
    const formattedCustomAttributeFilters = formatCustomAttributeFilters(customAttributeFilters)

    return [...formattedDemographicFilters, ...formattedCustomAttributeFilters]
  }

  const formatDemographicFilters = (filters) => {
    let formattedFilters = []

    filters.forEach((filter) => {
      if (filter.filter_type === 'numeric') {
        formattedFilters.push({
          is_demographic: true,
          name: filter.name,
          mode: 'Include only',
          comparator: 'between',
          min_value: filter.from || '',
          max_value: filter.to || '',
        })
      } else if (['select_box', 'check_box'].includes(filter.answer_type)) {
        const selectedOptions = filter.filter_options
          .filter(filterOption => filterOption.is_checked)
          .map(filterOption => filterOption.title)

        if (selectedOptions.length === 0)
          return

        formattedFilters.push({
          is_demographic: true,
          name: filter.name,
          mode: 'Include only',
          comparator: 'in',
          values: selectedOptions,
        })
      }
    })

    return formattedFilters
  }

  const formatCustomAttributeFilters = (filters) => {
    let formattedFilters = []

    filters.forEach((filter) => {
      if (filter.filter_type === 'numeric') {
        formattedFilters.push({
          name: filter.name,
          filter_type: filter.filter_type,
          mode: filter.mode,
          comparator: filter.comparator,
          value: ['blank', 'not blank', 'between'].includes(filter.comparator) ? '' : filter.value,
          values: [],
          min_value: filter.comparator === 'between' ? filter.from === 0 ? 0 : filter.from || '' : '',
          max_value: filter.comparator === 'between' ? filter.to === 0 ? 0 : filter.to || '' : '',
        })
      } else if (filter.filter_type === 'text') {
        formattedFilters.push({
          name: filter.name,
          filter_type: filter.filter_type,
          mode: filter.mode,
          comparator: filter.comparator,
          value: ['blank', 'not blank', 'in'].includes(filter.comparator) ? '' : filter.value,
          values: filter.comparator === 'in' ? filter.values : [],
          min_value: '',
          max_value: '',
        })
      }
    })
    return formattedFilters
  }

  const getFormattedCintProfilingVariables = (filters) => {
    if (isMturk)
      return []

    let formattedFilter = []
    filters.forEach(filter => {
      let checkedVariables

      if (filter.id !== ageFilterForCint.id && filter.id !== genderFilterForCint.id && filter.categoryName !== CINT_REGION_CATEGORY_NAME) {
        checkedVariables = filter.variables
          .filter(variable => variable.is_checked)
          .map(variable => variable.id)

        if (checkedVariables.length) {
          formattedFilter.push({
            filter_id: filter.id,
            variable_ids: checkedVariables,
          })
        }
      }
    })

    return formattedFilter
  }

  const getFormattedCintRegionVariables = (filters) => {
    if (isMturk)
      return []

    let formattedFilter = []
    filters.forEach(filter => {
      let checkedRegions

      if (filter.categoryName === CINT_REGION_CATEGORY_NAME) {
        checkedRegions = filter.variables.filter(variable => variable.is_checked).map(variable => variable.id)

        if (checkedRegions.length) {
          formattedFilter.push({
            filter_id: filter.id,
            variable_ids: checkedRegions,
          })
        }
      }
    })

    return formattedFilter
  }

  /* Remove params that are not applicable for selected Platform */
  const getFilteredTaskParams = (taskParameters) => {
    const keywords = props.run.task_parameters.keywords || []
    let propertiesToBeRemoved = []

    const taskParams = {
      ...taskParameters,
      payment_per_submission: parseFloat(taskParameters.payment_per_submission),
      keywords: keywords.join(','),
      task_data_url: getTaskDataUrl(taskParameters)
    }

    if (isCint) {
      propertiesToBeRemoved = [
        'is_hq_participants_only',
        'is_very_hq_enabled',
        'is_country_check_enabled',
        'screening_activity_link',
      ]
    }

    return removePropertiesFromObject(taskParams, propertiesToBeRemoved)
  }

  const isParticipantTabValid = () =>
    props.isParticipantsFormValid && (props.isScreeningEnabled ? props.isScreeningFormValid && !showBudgetChangedWarning : true)

  const isConfirmationTabValid = () => {
    return (props.isDetailsFormValid && props.isQualityFilterValid && props.isParticipantsFormValid && props.isActivityFormValid
      && props.task_parameters.is_configured_qs && props.task_parameters.is_completion_link_setup
      && (props.isScreeningEnabled ? props.task_parameters.is_screening_link_setup : true)
      && (props.isScreeningEnabled ? props.isScreeningFormValid && !showBudgetChangedWarning : true)
    )
  }

  const isCustomHQEnabled = () => {
    return (
      !props.task_parameters.is_very_hq_enabled &&
      !props.task_parameters.is_hq_participants_only && (
        props.task_parameters.minimum_platform_approval_rate > 0 ||
        props.task_parameters.minimum_platform_tasks_completed > 0
      )
    )
  }


  // Side effects
  useEffect(() => {
    if (props.createRunApiStatus === 'success' && props.publishedRunId) {
      dispatch(createAndStartRunReset())

      if(props.createdRunStatus !== 'draft') {
        props.history.push(`/runs/show?id=${props.publishedRunId}&isPublished=true`)
      } else {
        props.history.push(`/runs/show?id=${props.publishedRunId}`)
      }
    } else if (props.updateRunApiStatus === 'success') {
      dispatch(runUpdateReset())

      if(isPublishing) {
        props.history.push(`/runs/show?id=${runId}&isPublished=true`)
      } else {
        props.history.push(`/runs/show?id=${runId}`)
      }
    } else if (props.createRunApiStatus === 'error' || props.updateRunApiStatus === 'error') {
      setIsSaving(false)
      setIsPublishing(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.createRunApiStatus, props.updateRunApiStatus])

  // Get eligible participants count for Mturk
  useEffect(() => {
    const numberOfSubmissions = props.task_parameters.number_of_submissions
      ? parseInt(props.task_parameters.number_of_submissions)
      : 0

    if (props.isLoading || !isMturk || !numberOfSubmissions || isCustomHQEnabled())
      return

    const controller = new AbortController()
    const {signal} = controller
    if (mturkEligibleCountAbortController !== null)
      mturkEligibleCountAbortController.abort()
    setMturkEligibleCountAbortController(controller)

    const validFilters = props.filters.filter(filter => filter.is_filter_valid)
    const params = {
      platform_id: props.platform_id,
      task_id: props.task_id,
      country_name: props.task_parameters.country_name,
      is_hq_participants_only: props.task_parameters.is_hq_participants_only,
      is_isp_check_enabled: props.task_parameters.is_isp_check_enabled,
      is_country_check_enabled: props.task_parameters.is_country_check_enabled,
      is_attention_check_enabled: props.task_parameters.is_attention_check_enabled,
      is_very_hq_enabled: props.task_parameters.is_very_hq_enabled,
      prescreen_data: props.prescreen_data,
      prescreen_ids: getFormattedPrescreenIds(props.prescreen_ids, props.prescreen_data),
      custom_filters: JSON.stringify(getFormattedFilters(validFilters)),
      minimum_positly_pass_rate: props.task_parameters.minimum_positly_pass_rate,
      minimum_positly_approvals: props.task_parameters.minimum_positly_approvals,
    }

    dispatch(runFormQualifiedWorkersCountFetch(params, signal))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.platform,
    props.task_id,
    props.task_parameters.country_name,
    props.task_parameters.is_hq_participants_only,
    props.task_parameters.is_isp_check_enabled,
    props.task_parameters.is_country_check_enabled,
    props.task_parameters.is_attention_check_enabled,
    props.task_parameters.is_very_hq_enabled,
    props.prescreen_ids,
    props.prescreen_data,
    props.filters,
    props.task_parameters.number_of_submissions,
    props.task_parameters.minimum_positly_pass_rate,
    props.task_parameters.minimum_positly_approvals,
    props.task_parameters.minimum_platform_approval_rate,
    props.task_parameters.minimum_platform_tasks_completed
  ])

  useEffect(() => {
    scrollToTop()
  }, [activeTab]);

  // UI template
  return (
    <>
      <div className="row task-list-header align-items-center">
        <div className="col-lg-12">
          <ul className="status_bar">
            <li
              role="presentation"
              className={
                `item step_1 clickable
                ${isTabActive(TABS.DETAILS) && ' active'}
                ${props.isDetailsFormValid && props.isQualityFilterValid && ' passed'} `
              }
              onClick={() => setActiveTab(TABS.DETAILS)}
            >
              <div className="circle"/>
              <p>{TABS.DETAILS.navigationText}</p>
            </li>
            <li
              className={
                `item step_2 clickable
                ${isTabActive(TABS.PARTICIPANTS) && ' active'}
                ${isParticipantTabValid() ? ' passed' : ''}`
              }
              onClick={() => setActiveTab(TABS.PARTICIPANTS)}
            >
              <div className="circle"/>
              <p>{TABS.PARTICIPANTS.navigationText}</p>
            </li>
            {isActivityLanguageEnglish() && (
              <li
                className={
                  `item step_3 clickable passed 
                  ${isTabActive(TABS.ADDITIONAL_ATTRIBUTES) && ' active'}`
                }
                onClick={() => setActiveTab(TABS.ADDITIONAL_ATTRIBUTES)}
              >
                <div className="circle"/>
                <p>{TABS.ADDITIONAL_ATTRIBUTES.navigationText}</p>
              </li>
            )}
            <li
              className={
                `item step_4 clickable
                ${isTabActive(TABS.ACTIVITY) && ' active'}
                ${props.isActivityFormValid && ' passed'}`
              }
              onClick={() => setActiveTab(TABS.ACTIVITY)}
            >
              <div className="circle"/>
              <p>{TABS.ACTIVITY.navigationText}</p>
            </li>
            <li
              className={
                `item step_5 clickable
                ${isTabActive(TABS.CONFIRMATION) && ' active'}
                ${isConfirmationTabValid() ? ' passed' : ''}`
              }
              onClick={() => setActiveTab(TABS.CONFIRMATION)}
            >
              <div className="circle"/>
              <p>{TABS.CONFIRMATION.navigationText}</p>
            </li>
          </ul>
        </div>
      </div>
      <div className="metrics project_detail filter participants activity mt-2">
        <div className="wrap_title row align-items-center gap-1 run-form-title-wrapper">
          <h1>
            <span>{activeTab.titleText}</span>
          </h1>

          <div className="d-flex align-items-center gap-1 run-form-help-btns">
            {isTabActive(TABS.CONFIRMATION) && isMturk && (
              <div className="wrap_checkbox d-flex flex-row mt-0">
                <label className="switch">
                  <input
                    id="advanced"
                    type="checkbox"
                    checked={showAdvancedSettings}
                    onChange={() => setShowAdvancedSettings(!showAdvancedSettings)}
                  />
                  <span className="slider round"/>
                </label>
                <span className="po-text ml-2">
                Advanced settings
              </span>
              </div>
            )}
            <DemoButtons videoStartTimeInSecs={activeTab.explainerVideoStartTimeInSecs} page={activeTab.page}/>
          </div>
        </div>
        {props.isLoading ? (
          <Spinner/>
        ) : (
          <div aria-labelledby="run-details" role="tabpanel">
            <RunFormDetailsTab
              {...commonProps}
              defaultPlatformId={defaultPlatformId}
              setDefaultPlatformId={setDefaultPlatformId}
              setOldCountryname={setOldCountryname}
            />
            <div className="row run-form">
              <div className="col-lg-8 col-md-12 left">
                <RunFormActivityTab
                  {...commonProps}
                />
                <RunFormParticipantsTab
                  {...commonProps}
                  oldCountryName={oldCountryname}
                />
                <RunFormAdditionalAttributesTab
                  {...commonProps}
                />
                <RunFormConfirmationTab
                  {...commonProps}
                  {...propsForConfirmationTab}
                  setShowAddCardWarning={setShowAddCardWarning}
                  addCardCallbackRef={addCardCallbackRef}
                />
              </div>
              <div className="col-lg-4 col-md-12 right">
                <RunFormOverview {...commonProps}/>
              </div>
            </div>
          </div>
        )}
      </div>

      <SpendingLimitWarning
        showSpendingLimitWarning={showSpendingLimitWarning}
        setShowSpendingLimitWarning={setShowSpendingLimitWarning}
      />

      <AddCardWarning
        showAddCardWarning={showAddCardWarning}
        setShowAddCardWarning={setShowAddCardWarning}
        callbackRef={addCardCallbackRef}
      />
    </>
  )
}

const mapStateToProps = state => {
  if (!state.runFormOverview || !state.runForm)
    return {isLoading: true, platform: {}, task_parameters: {}}

  return {
    isLoading: state.runForm.isLoading || state.platformList?.isLoading,
    isDetailsFormValid: state.runFormOverview.isDetailsFormValid,
    isActivityFormValid: state.runFormOverview.isActivityFormValid,
    isParticipantsFormValid: state.runFormOverview.isParticipantsFormValid,
    isScreeningFormValid: state.runFormOverview.isScreeningFormValid,
    isQualityFilterValid: state.runFormOverview.isQualityFilterValid,
    platform: state.runFormOverview.platform,
    run: state.runFormOverview || {},
    createRunApiStatus: state.createAndStartRun?.status,
    updateRunApiStatus: state.runUpdate?.status,
    publishedRunId: state.createAndStartRun?.run?.id,
    platform_id: state.runFormOverview.platform_id || '',
    task_id: state.runFormOverview.task_id || '',
    task_parameters: state.runFormOverview.task_parameters || {},
    filters: state.runFormOverview.filters || [],
    prescreen_data: state.runFormOverview.prescreen_data || {},
    prescreen_ids: state.runFormOverview.prescreen_ids || [],
    activityLanguageId: state.runFormOverview.activity_language_id,
    languages: state.activityLanguages?.languages,
    isScreeningEnabled: state.runFormOverview.agree_screening_guidelines,
    cintQuotas: state.runFormOverview.cint_quotas || [],
    runsCount: state.runForm.runs_count,
    positly_user_id: state.currentUser?.uid,
    createdRunStatus: state.createAndStartRun?.run?.status
  }
}

export default withRouter(connect(mapStateToProps)(EnhancedRunForm))
