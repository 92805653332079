import React from 'react'
import swal from 'sweetalert'
import {errorToast} from '../helpers/notification'
import {formatAsCurrency} from '../run/enhanced-run-form/RunUtils'
import Cookies from 'universal-cookie'
import {fetchCurrentUserBalance} from './fetch/User'

const $ = window.$

export const capitalize = (text, delimiter = null) => {
  if (typeof text == 'string') {
    if (delimiter) {
      return text.split(delimiter).map(data => `${data.charAt(0).toUpperCase()}${data.substr(1).toLowerCase()}`).join(' ')
    } else {
      return `${text.charAt(0).toUpperCase()}${text.substr(1).toLowerCase()}`
    }
  } else {
    return ''
  }
}

export const toBoolean = (value, defaultValue) => {
  let bool = defaultValue

  if (typeof (value) === 'boolean')
    bool = value
  else if (typeof (value) === 'string')
    bool = value === 'true'

  return bool
}

export const updateCurrentUserBalance = async (props) => {
    const response = await fetchCurrentUserBalance();
    const data = await response.json();

    if (data.status === 'error') {
      console.error('updateCurrentUserBalance(): ', data.errors)
      return null
    }

    const user = {
      ...props.currentUser,
      credits_unused_in_dollar: data.credits_unused_in_dollar,
      credits_in_progress_in_dollar: data.credits_in_progress_in_dollar,
      credit_balance_in_dollars: data.credit_balance_in_dollars,
    };
    // update user data in Redux
    props.updateCurrentUser(user)

    return user
};


export const handleCharge = async (props) => {
  const isBillablePlatform = (platformType) => ['Positly', 'Cint'].includes(platformType)

  const executeCallback = () => {
    if (Array.isArray(props.callbackParam)) {
      props.callback(...props.callbackParam)
    } else {
      props.callback(props.callbackParam)
    }
  }

  const confirmAndPublish = () => {
    swal({
      title: 'Are you ready to publish this run? ',
      icon: 'warning',
      buttons: {
        cancel: 'Cancel',
        confirm: {text: 'Publish', className: 'btn-primary'}
      }
    }).then((value) => {
      if (value)
        return executeCallback()
      else
        return false
    })
  }

  // If platform is not billable, execute callback and return
  if (!isBillablePlatform(props.platformType)) {
    if (props.isRunUpdate)
      return confirmAndPublish()
    else
      return executeCallback()
  }

  const totalCost = parseFloat(props.totalCost)
  // Await the balance update before continuing
  const user = await updateCurrentUserBalance(props)

  if (!user) {
    props.errorCallback()
    errorToast('We are sorry but something went wrong. Please try again.');
    return;
  }

  const getAdditionalCost = () => {
      if (totalCost && user) {
      return parseFloat((totalCost - user.credits_unused_in_dollar).toFixed(2))
    }
    return 0
  }

  // Show spending limit warning
  if (totalCost > user.spending_limit) {
    if (props.spendingLimitCallbackRef)
      props.spendingLimitCallbackRef.current = props.warningCallback

    return props.setShowSpendingLimitWarning(true)
  }

  let additionalAmount = getAdditionalCost()

  // If additional cost is < 0.01, execute callback and return
  if (additionalAmount < 0.01) {
    if (props.isRunUpdate)
      return confirmAndPublish()
    else
      return executeCallback()
  }

  // Show add card warning
  if (!user.is_card_linked) {
    props.addCardCallbackRef.current = props.warningCallback
    return props.setShowAddCardWarning(true)
  }

  let msg
  if (additionalAmount < 15) {
    const extraCredit = 15 - additionalAmount
    msg = `There is a minimum charge of $15.00. The extra ${formatAsCurrency(extraCredit)} of credit will \
          remain on your account. You can use it in future runs or have it refunded to your card.`
  } else {
    msg = `Your card will be charged for ${formatAsCurrency(additionalAmount)}`
  }

  swal({
    title: 'Your card will be charged ',
    text: msg,
    icon: 'warning',
    buttons: {
      cancel: 'Cancel',
      confirm: {text: 'Proceed', className: 'btn-primary'}
    }
  }).then((confirmed) => {
    if (!confirmed) return

    executeCallback()
  })
}

export const shallowEqual = (object1, object2) => {
  const keys1 = Object.keys(object1)
  const keys2 = Object.keys(object2)

  if (keys1.length !== keys2.length)
    return false

  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false
    }
  }

  return true
}

export const checkIfFiltersAreValid = (filters) => (
  filters.every(filter => filter.is_filter_valid)
)

export const checkIfQuotasAreValid = (quotas) => (
  quotas.every(quota => quota.isValid)
)

export const calculateCost = (number_of_submissions, payment_per_submission, serviceFeePercent) => {
  if (!number_of_submissions || !payment_per_submission || !serviceFeePercent) return 0

  const cost = number_of_submissions * payment_per_submission
  const serviceCost = cost * (serviceFeePercent / 100);

  return (cost + serviceCost).toFixed(2)
}

export const handlePublishRunError = (errors, platformMode) => {
  const error = errors.join(', ')
  const url = platformMode === 'live' ? 'https://requester.mturk.com/account' : 'https://requestersandbox.mturk.com/account'

  if (error.includes('Amazon Mechanical Turk account does not have sufficient funds to create this run')) {
    const errorMessage = () => {
      return (
        <div>
          Your {error}.{' '}
          <a href={url} className="react-toastify-link" target="_blank" rel="noreferrer">
            Add money to your Mechanical Turk Account</a>
        </div>
      )
    }
    errorToast(errorMessage, {autoClose: false})
  } else if (error.includes('Positly is currently not available')) {
    const errorMessage = () => {
      return (
        <div>
          Positly is currently not available. We have been notified about this issue and expect to be back up
          within a couple of hours. Sorry for the inconvenience. For more information, please contact us at
          {' '} <a href="mailto:hi@positly.com" className="react-toastify-link">hi@positly.com</a>
        </div>
      )
    }
    errorToast(errorMessage, {autoClose: false})
  } else {
    errorToast(error, {autoClose: false})
  }
}

export const urlWithProtocol = (url) => {
  if (url.match(/^(http|https)/)) {
    return url
  } else {
    return `https://${url}`
  }
}

const checkLocalStorageSupport = () => {
  try {
    if (window.localStorage === undefined) return false

    localStorage.setItem('isLocalStorageSupported', 'true')
    localStorage.removeItem('isLocalStorageSupported')
    return true
  } catch {
    return false
  }
}
export const isLocalStorageSupported = checkLocalStorageSupport()

const checkCookieSupport = () => {
  try {
    document.cookie = 'is_cookie_supported=true'
    const cookiesEnabled = document.cookie.indexOf('is_cookie_supported=') !== -1
    document.cookie = 'is_cookie_supported=true; expires=Thu, 01-Jan-1970 00:00:01 GMT'
    return cookiesEnabled
  } catch (e) {
    return false
  }
}
export const isCookieSupported = checkCookieSupport()

/* Pick only given properties from an object */
export const pickPropertiesFromObject = (object, requiredProperties = []) => {
  const newObject = {}
  requiredProperties.forEach(property => newObject[property] = object[property])

  return newObject
}

/* Remove given properties from an object */
export const removePropertiesFromObject = (object, propertiesToBeRemoved) => {
  propertiesToBeRemoved.forEach(property => delete object[property])

  return object
}

/* Get font size for project metrics based on number of digits */
export const getProjectSpentFontSize = (content) => {
  const numberOfDigits = content && content.toString().replace('.', '').length

  let fontSize = 35

  if (numberOfDigits === 6)
    fontSize = 31
  else if (numberOfDigits === 7)
    fontSize = 27
  else if (numberOfDigits === 8)
    fontSize = 24
  else if (numberOfDigits > 8)
    fontSize = 22

  return `${fontSize}px`
}

/* Get font size for run metrics based on number of digits */
export const getRunBudgetSpentFontSize = (spent, budget) => {
  const spentLength = spent && spent.toString().replace('.', '').length
  const budgetLength = budget && budget.toString().replace('.', '').length

  let fontSize = 24

  if (spentLength > 7 || budgetLength > 7)
    fontSize = 16
  else if (spentLength === 7 || budgetLength === 7)
    fontSize = 19
  else if (spentLength === 6 || budgetLength === 6)
    fontSize = 21

  return `${fontSize}px`
}

export const toggleAccordion = (event) => {
  let item = $(event.target).parents('.accordion_item')
  let info = item.find('.info')
  item.siblings('.active_block').removeClass('active_block').children('.info').stop(!0, !0).slideToggle()
  item.toggleClass('active_block')
  info.slideToggle()
}

export const setAuth = (data) => {
  if (!isLocalStorageSupported)
    return errorToast('Please enable local storage to proceed!')

  localStorage.setItem('access_token', data.access_token)
  if(data.account_type === 'researcher') {
    localStorage.setItem('is_recruiter', 'true')
  }
}

export const clearAuth = () => {
  if (!isLocalStorageSupported) return

  localStorage.removeItem('access_token')
  localStorage.removeItem('client')
  localStorage.removeItem('uid')
  localStorage.removeItem('name')
  localStorage.removeItem('full_name')
  localStorage.removeItem('email')
  localStorage.removeItem('is_recruiter')
}

export const authHeaders = () => {
  if (!isLocalStorageSupported) return {}

  return {
    'Authorization': `Bearer ${localStorage.getItem('access_token')}`
  }
}

export const setStorageItem = (key, value) => {
  const stringifiedValue = JSON.stringify(value)
  if (isLocalStorageSupported)
    localStorage.setItem(key, stringifiedValue)

  if (isCookieSupported) {
    const cookies = new Cookies()
    cookies.set(key, stringifiedValue)
  }
}

export const getStorageItem = (key) => {
  if (isLocalStorageSupported)
    try {
      return JSON.parse(localStorage.getItem(key))
    } catch {
      return localStorage.getItem(key)
    }

  if (isCookieSupported) {
    const cookies = new Cookies()

    try {
      return JSON.parse(cookies.get(key))
    } catch {
      return cookies.get(key)
    }
  }

  return null
}

export const removeStorageItem = (key) => {
  if (isLocalStorageSupported)
    localStorage.removeItem(key)

  if (isCookieSupported) {
    const cookies = new Cookies()
    cookies.remove(key)
  }

  return null
}

export const snakeCaseToCamelCase = (string) => {
  return string.replace(
    /(?!^)_(.)/g,
    (_, char) => char.toUpperCase()
  )
}

export const clearLinkVerificationData = () => {
  if (isLocalStorageSupported) {
    for (const key in localStorage) {
      if (key.startsWith('lv_')) localStorage.removeItem(key)
    }
  }

  if (isCookieSupported) {
    const cookies = new Cookies()
    for (const key in cookies.getAll()) {
      if (key.startsWith('lv_')) cookies.remove(key)
    }
  }
}

export const pluralizeOnMany = (word, count) => {
  if (count > 1) {
    return word + 's'
  } else {
    return word
  }
}

export const allowOnlyNumbers = (e) => {
  const code = e.which ? e.which : e.keyCode
  if (code > 31 && (code < 48 || code > 57)) {
    e.preventDefault()
  }
}

export const preventPaste = (e) => {
  if (e.clipboardData.getData('Text').match(/[^\d]/)) {
    e.preventDefault()
  }
}

export const getDownloadURL = (path) => {
  let origin = window.location.origin;


  if (origin.includes('localhost')) {
    origin = 'http://localhost:8000'
  }

  return origin + path;
}

export const scrollToTop = () => {
  window.scrollTo(0, 0)
}
